import { setCookie, getCookie } from './shared/cookies';

window.addEventListener('DOMContentLoaded', () => {
  // お気に入りボタンクリック時
  $(document).on('click', '.js-cookie-fav-btn', (e) => {
    let fav_job_ids = getCookie('favorite_job_ids') || [];
    const targetElm = e.target;
    const id = $(targetElm).data('id');
    const defaultText = $(targetElm).data('defaultText');
    const doneText = $(targetElm).data('doneText');

    let index = fav_job_ids.indexOf(id);

    if (index === -1) {
      fav_job_ids.push(id);
    } else {
      fav_job_ids.splice(index, 1);
    }

    setCookie('favorite_job_ids', JSON.stringify(fav_job_ids));

    const favoriteBtns = $('.js-cookie-fav-btn');

    $.each(favoriteBtns, (j, btn) => {
      if ($(btn).data('id') === id) {
        toggle(btn, defaultText, doneText);
      }
    });
  });

  // メソッド
  function toggle(favBtn, defaultText, doneText) {
    const favOn = {
      class:
        'c-button -border-orange -heart-icon -sp-text-sm js-cookie-fav-btn',
      innerHTML: defaultText,
    };

    const favOff = {
      class:
        'c-button -border-orange -heart-icon -sp-text-sm js-cookie-fav-btn -solid -done',
      innerHTML: doneText,
    };

    if (favBtn.className.includes(favOff.class)) {
      // お気に入り登録済みのクラスを削除
      favBtn.classList.remove('-solid', '-done');
      favBtn.innerHTML = favOn.innerHTML;
    } else if (favBtn.className.includes(favOn.class)) {
      // お気に入り登録済みのクラスを追加
      favBtn.classList.add('-solid', '-done');
      favBtn.innerHTML = favOff.innerHTML;
    }
  }
});
